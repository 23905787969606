import React, { useState, useCallback } from 'react';
import '../../css/sections/RSVPSection.css';

// Define the API host and port
const DEBUG = false;
const API_HOST = DEBUG ? 'http://localhost:4000' : '';

interface Guest {
  name: string;
  age: number;
  isChild: boolean;
  wasAdded: boolean;
  eventResponses: { [eventId: string]: boolean };
}

interface Event {
  id: string;
  name: string;
}

interface GuestResponse {
  id: number;
  partyName: string;
  guestsInParty: Guest[];
  maxAdults: number;
  maxChildren: number;
  invitedEvents: Event[];
  email: string;
  additionalComments: string;
}

export const RSVPSection: React.FC = () => {
  const [guestID, setGuestID] = useState(0);
  const [guestName, setGuestName] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [partyName, setPartyName] = useState('');
  const [adults, setAdults] = useState<Guest[]>([]);
  const [addedAdults, setAddedAdults] = useState<Guest[]>([]);
  const [children, setChildren] = useState<Guest[]>([]);
  const [email, setEmail] = useState('');
  const [events, setEvents] = useState<Event[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [maxAdults, setMaxAdults] = useState(0);
  const [maxChildren, setMaxChildren] = useState(0);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [additionalComments, setAdditionalComments] = useState('');

  const clearMessages = useCallback(() => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsSubmitDisabled(false);
  }, []);

  const handleFindInvitation = async (e: React.FormEvent) => {
    e.preventDefault();
    clearMessages();
    try {
      const response = await fetch(`${API_HOST}/api/rsvp/check-guest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: guestName }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch guest information');
      }

      const data: GuestResponse = await response.json();
      const adultGuests = data.guestsInParty.filter(guest => !guest.isChild && !guest.wasAdded);
      const addedAdultGuests = data.guestsInParty.filter(guest => !guest.isChild && guest.wasAdded);
      const childGuests = data.guestsInParty.filter(guest => guest.isChild);
      setGuestID(data.id);
      setAdults(adultGuests);
      setAddedAdults(addedAdultGuests);
      setChildren(childGuests);
      setPartyName(data.partyName);
      setMaxChildren(data.maxChildren);
      setMaxAdults(data.maxAdults);
      setEvents(data.invitedEvents);
      setAdditionalComments(data.additionalComments);
      setEmail(data.email);
      setShowForm(true);
      setErrorMessage('');
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    clearMessages();
    try {
      const updatedGuestResponse: GuestResponse = {
        id: guestID, 
        partyName: partyName,
        guestsInParty: [...adults, ...addedAdults, ...children],
        maxAdults: maxAdults,
        maxChildren: maxChildren,
        invitedEvents: events,
        email: email,
        additionalComments: additionalComments
      };

      const response = await fetch(`${API_HOST}/api/rsvp/update-guest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedGuestResponse),
      });

      if (!response.ok) {
        throw new Error('Failed to update RSVP');
      }

      setSuccessMessage('Your RSVP has been successfully submitted!');
      setIsSubmitDisabled(true);
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred while submitting your RSVP. Please try again later.');
    }
  };

  const handleGuestEventResponse = (guestIndex: number, eventId: string, isAttending: boolean, isChild: boolean, isAddedGuest: boolean) => {
    clearMessages();
    const updatedGuests = isChild ? [...children] : isAddedGuest ? [...addedAdults] : [...adults];
    updatedGuests[guestIndex].eventResponses[eventId] = isAttending;
    isChild ? setChildren(updatedGuests) : isAddedGuest ? setAddedAdults(updatedGuests) : setAdults(updatedGuests);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearMessages();
    setEmail(e.target.value);
  };

  const handleCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    clearMessages();
    setAdditionalComments(e.target.value);
  };

  const handleAddChild = () => {
    if (children.length < maxChildren) {
      const newChild: Guest = {
        name: '',
        age: 0,
        isChild: true,
        wasAdded: true,
        eventResponses: events.reduce((acc, event) => ({ ...acc, [event.id]: false }), {})
      };
      setChildren([...children, newChild]);
    }
  };

  const handleRemoveChild = (index: number) => {
    const updatedChildren = children.filter((_, i) => i !== index);
    setChildren(updatedChildren);
  };


  const handleChildNameChange = (index: number, name: string) => {
    clearMessages();
    const updatedChildren = [...children];
    updatedChildren[index].name = name;
    setChildren(updatedChildren);
  };

  const handleChildAgeChange = (index: number, age: number) => {
    clearMessages();
    const updatedChildren = [...children];
    updatedChildren[index].age = age;
    setChildren(updatedChildren);
  };

  const handleAddGuest = () => {
    if (adults.length + addedAdults.length < maxAdults) {
      const newGuest: Guest = {
        name: '',
        age: 0,
        isChild: false,
        wasAdded: true,
        eventResponses: events.reduce((acc, event) => ({ ...acc, [event.id]: false }), {})
      };
      setAddedAdults([...addedAdults, newGuest]);
    }
  };

  const handleRemoveGuest = (index: number) => {
    const updatedAddedAdults = addedAdults.filter((_, i) => i !== index);
    setAddedAdults(updatedAddedAdults);
  };

  const handleGuestNameChange = (index: number, name: string) => {
    clearMessages();
    const updatedAddedAdults = [...addedAdults];
    updatedAddedAdults[index].name = name;
    setAddedAdults(updatedAddedAdults);
  };

  return (
    <div className="section-section">
      <div className="section-container">
        <h2>RSVP</h2>
        {!showForm ? (
          <div>
            <p>Please enter your full name to find your invitation. You can always update your RSVP later by revisiting this page. Please contact us at <a href="mailto:nisha.and.tarak@gmail.com">nisha.and.tarak@gmail.com</a> if you are having trouble RSVPing.</p>
            <br/>
            <form onSubmit={handleFindInvitation} className="find-invitation-form">
            <input
              type="text"
              value={guestName}
              onChange={(e) => setGuestName(e.target.value)}
              placeholder="Enter your full name"
              required
            />
            <button type="submit">Find Invitation</button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="rsvp-form">
            {partyName !== "" ? <h3 className="party-name">{partyName}</h3> : null}
            <div className="adults-section">
              {adults.map((guest, guestIndex) => (
                <div key={guestIndex} className="guest-section">
                  <h5>{guest.name}</h5>
                    {events.map((event) => (
                      <div key={event.id} className="event-item">
                        <label>
                          <input
                            type="checkbox"
                            checked={guest.eventResponses[event.id] || false}
                            onChange={(e) => handleGuestEventResponse(guestIndex, event.id, e.target.checked, false, false)}
                          />
                          <span>{event.name}</span>
                        </label>
                      </div>
                    ))}
                </div>
              ))}
            </div>

            {/* Added Adults */}
            <div className="children-section">
              {addedAdults.map((guest, guestIndex) => (
                <div key={guestIndex} className="guest-section child-section">
                  <div className="child-info">
                    <p>Added Guest:</p>
                    <input
                      type="text"
                      value={guest.name}
                      onChange={(e) => handleGuestNameChange(guestIndex, e.target.value)}
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="event-list">
                    {events.map((event) => (
                      <div key={event.id} className="event-item">
                        <label>
                          <input
                            type="checkbox"
                            checked={guest.eventResponses[event.id] || false}
                            onChange={(e) => handleGuestEventResponse(guestIndex, event.id, e.target.checked, true, true)}
                          />
                          <span>{event.name}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <button 
                    type="button" 
                    onClick={() => handleRemoveGuest(guestIndex)} 
                    className="rsvp-button remove-child-btn"
                  >
                    Remove
                  </button>
                </div>
              ))}
              {adults.length + addedAdults.length < maxAdults && (
                <button 
                  type="button" 
                  onClick={handleAddGuest} 
                  className="rsvp-button add-child-btn"
                >
                  Add Guest
                </button>
              )}
            </div>

            {/* Children */}
            <div className="children-section">
              {children.map((child, childIndex) => (
                <div key={childIndex} className="guest-section child-section">
                  <div className="child-info">
                    <p>Added Child:</p>
                    <input
                      type="text"
                      value={child.name}
                      onChange={(e) => handleChildNameChange(childIndex, e.target.value)}
                      placeholder="Name"
                      required
                    />
                    <input
                      type="number"
                      value={child.age > 0 ? child.age : ''}
                      onChange={(e) => handleChildAgeChange(childIndex, parseInt(e.target.value))}
                      placeholder="Age"
                      required
                      min="0"
                      max="17"
                    />
                  </div>
                  <div className="event-list">
                    {events.map((event) => (
                      <div key={event.id} className="event-item">
                        <label>
                          <input
                            type="checkbox"
                            checked={child.eventResponses[event.id] || false}
                            onChange={(e) => handleGuestEventResponse(childIndex, event.id, e.target.checked, true, true)}
                          />
                          <span>{event.name}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <button 
                    type="button" 
                    onClick={() => handleRemoveChild(childIndex)} 
                    className="rsvp-button remove-child-btn"
                  >
                    Remove
                  </button>
                </div>
              ))}
              {children.length < maxChildren && (
                <button 
                  type="button" 
                  onClick={handleAddChild} 
                  className="rsvp-button add-child-btn"
                >
                  Add Child
                </button>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="additionalComments">Additional Comments:</label>
              <textarea
                id="additionalComments"
                value={additionalComments}
                onChange={handleCommentsChange}
                rows={4}
                placeholder="Any additional comments or messages for us?"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email for Confirmation:</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <button type="submit" disabled={isSubmitDisabled}>Submit RSVP</button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
          </form>
        )}
      </div>
    </div>
  );
};