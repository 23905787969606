import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/Home.css';
import '../css/Section.css';
import ganeshLogo from '../images/ganesh-logo.png'; // Adjust the path as needed
import balajiLogo from '../images/balaji-logo.png'; // Adjust the path as needed

// Import images
import heroImage from '../images/bg/scaled_hero-bg.jpg';
import aboutUsImage from '../images/bg/scaled_about-us-bg.jpg';
import eventsImage from '../images/bg/scaled_events-bg.jpg';
import rsvpImage from '../images/bg/scaled_rsvp-bg.jpg';
import faqImage from '../images/bg/scaled_faq-bg.jpg';
import travelImage from '../images/bg/scaled_travel-bg.jpg';
import registryImage from '../images/bg/scaled_registry-bg.jpg';
import galleryImage from '../images/bg/scaled_gallery-bg.jpg';

import heroImageMobile from '../images/bgmobile/scaled_hero-bg.jpg';
import aboutUsImageMobile from '../images/bgmobile/scaled_about-us-bg.jpg';
import eventsImageMobile from '../images/bgmobile/scaled_events-bg.jpg';
import rsvpImageMobile from '../images/bgmobile/scaled_rsvp-bg.jpg';
import faqImageMobile from '../images/bgmobile/scaled_faq-bg.jpg';
import travelImageMobile from '../images/bgmobile/scaled_travel-bg.jpg';
import registryImageMobile from '../images/bgmobile/scaled_registry-bg.jpg';
import galleryImageMobile from '../images/bgmobile/scaled_gallery-bg.jpg';

// Import section components
import { HeroSection } from './sections/HeroSection';
import { OurStorySection } from './sections/OurStorySection';
import { EventsSection } from './sections/EventsSection';
import { RSVPSection } from './sections/RSVPSection';
import { FAQSection } from './sections/FAQSection';
import { TravelSection } from './sections/TravelSection';
import { RegistrySection } from './sections/RegistrySection';
import { GallerySection } from './sections/GallerySection';

import MobileMenu from './MobileMenu'; // New import
import { FaBars } from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Add this import

const sections = [
  { id: 'home', Component: HeroSection, image: heroImage, mobileImage: heroImageMobile, title: 'Home'},
  { id: 'our-story', Component: OurStorySection, image: aboutUsImage, mobileImage: aboutUsImageMobile, title: 'Our Story'},
  { id: 'events', Component: EventsSection, image: eventsImage, mobileImage: eventsImageMobile, title: 'Events'},
  { id: 'travel', Component: TravelSection, image: travelImage, mobileImage: travelImageMobile, title: 'Travel & Hotels' },
  { id: 'faq', Component: FAQSection, image: faqImage, mobileImage: faqImageMobile, title: 'Q + A' },
  { id: 'registry', Component: RegistrySection, image: registryImage, mobileImage: registryImageMobile, title: 'Registry' },
  { id: 'gallery', Component: GallerySection, image: galleryImage, mobileImage: galleryImageMobile, title: 'Gallery' },
  { id: 'rsvp', Component: RSVPSection, image: rsvpImage, mobileImage: rsvpImageMobile, title: 'RSVP' },
];

const Home: React.FC = () => {
  const { section } = useParams<{ section?: string }>();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(section || 'home');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const [showIndicators, setShowIndicators] = useState(!section || section === 'home');

  const scrollToSection = (id: string) => {
    setShowIndicators(false);
    setActiveSection(id);
    navigate(`/${id}`);
  };

  useEffect(() => {
    if (section) {
      setActiveSection(section);
    }
  }, [section, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getCurrentSectionTitle = () => {
    const currentSection = sections.find(section => section.id === activeSection);
    return currentSection ? currentSection.title : 'Menu';
  };

  const nextSection = () => {
    const currentIndex = sections.findIndex(s => s.id === activeSection);
    if (currentIndex < sections.length - 1) {
      const nextId = sections[currentIndex + 1].id;
      setActiveSection(nextId);
      navigate(`/${nextId}`);
    }
  };

  const prevSection = () => {
    const currentIndex = sections.findIndex(s => s.id === activeSection);
    if (currentIndex > 0) {
      const prevId = sections[currentIndex - 1].id;
      setActiveSection(prevId);
      navigate(`/${prevId}`);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const difference = touchStartX.current - touchEndX.current;
    if (difference > 75) {
      // Handle left swipe
      setShowIndicators(false);
      nextSection();
    } else if (difference < -75) {
      // Handle right swipe
      setShowIndicators(false);
      prevSection();
    }
  };

  const isFirstSection = activeSection === sections[0].id;
  const isLastSection = activeSection === sections[sections.length - 1].id;

  return (
    <>
      <div 
        className="home-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <header className="sticky-header">
          <div className="header-content">
            <img src={ganeshLogo} alt="Ganesh Logo" className="ganesh-logo" />
            <nav className="section-outline desktop-nav">
              <ul>
                {sections.map((section) => (
                  <li
                    key={section.id}
                    className={section.id === activeSection ? 'active' : ''}
                    onClick={() => scrollToSection(section.id)}
                  >
                    {section.title}
                  </li>
                ))}
              </ul>
            </nav>
            <button 
              className="mobile-menu-toggle" 
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <FaBars size={14}/>&nbsp;&nbsp;{getCurrentSectionTitle()}
            </button>
            <img src={balajiLogo} alt="Balaji Logo" className="balaji-logo" />
          </div>
        </header>

        <MobileMenu 
          isOpen={isMobileMenuOpen} 
          onClose={() => setIsMobileMenuOpen(false)}
          sections={sections}
          activeSection={activeSection}
          onSectionClick={scrollToSection}
        />

        {sections.map((section) => (
          <div
            key={section.id}
            className={`background ${section.id === activeSection ? 'active' : ''}`}
            style={{ backgroundImage: `url(${isMobile ? section.mobileImage : section.image})` }}
          />
        ))}
        {sections.map((section) => (
          <section
            key={section.id}
            id={section.id}
            className={`section ${section.id === activeSection ? 'active' : ''}`}
          >
            <div className="content-container">
              {section.Component && <section.Component />}
            </div>
          </section>
        ))}
        
        {isMobile && showIndicators && (
          <>
            {!isFirstSection && (
              <div className="swipe-indicator left">
                <FaChevronLeft onClick={prevSection}/>
              </div>
            )}
            {!isLastSection && (
              <div className="swipe-indicator right">
                <FaChevronRight onClick={nextSection}/>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Home;
