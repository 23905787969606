import React from 'react';
import '../../css/sections/EventsSection.css';

// Remove the import for react-icons
// import { FaCalendarAlt, FaClock } from 'react-icons/fa';

interface Event {
  date: string;
  time: string;
  title: string;
  description: string;
  dressCode?: string; // Add this line
}

const events: Event[] = [
  {
    date: 'January 17, 2025',
    time: '6:00 PM',
    title: 'Sangeet / Welcome Dinner',
    description: "This ceremony is observed during the evening a day or two prior to the wedding. Both the bride’s and the groom’s family come together at a common venue and perform songs and dance routines. The ritual presents an informal setting for the two families to get to know each other well.",
    dressCode: 'Semi-formal or cocktail (Indian or Western)',
  },
  {
    date: 'January 18, 2025',
    time: '9:00 AM',
    title: 'Baraat / Groom\'s Procession',
    description: "The wedding procession that starts from the groom's house and ends at the bride's. However, since the groom lives too far from the bride, we will start ours around the corner from the enterance of the Richmond Marriot and end at the entrance of the venue. The groom will arrive on a horse, along with his friends and family, who will walk with him while dancing to live music and a band. The bride's guests are welcome to join! The bride's mother and sister will then welcome the groom's family at the entrance.",
    dressCode: 'Formal attire (Indian or Western)',
  },
  {
    date: 'January 18, 2025',
    time: '10:00 AM',
    title: 'Wedding Ceremony',
    description: 'A traditional Hindu ceremony filled with vibrant colors and meaningful rituals. The ceremony will take place under a canopy-like structure called a mandap. A Hindu priest will officiate the ceremony and numerous relatives will perform specific roles under his guidance. The priest will recite verses in Sanskrit, but will also provide English explanations of the rituals. The ceremony will involve both Telugu and Gujarati customs.',
    dressCode: 'Formal attire (Indian or Western)',
  },
  {
    date: 'January 18, 2025',
    time: '12:00 PM',
    title: 'Lunch',
    description: 'After the ceremony, enjoy a delicious meal with family and friends.',
    dressCode: 'Formal attire (Indian or Western)',
  },
  {
    date: 'January 18, 2025',
    time: '6:00 PM',
    title: 'Cocktail Hour',
    description: 'Get the evening started with cocktails, beer and wine, and enjoy both passed and stationed hors d\'oeuvres with Indian flavors.',
    dressCode: 'Black tie optional (Indian or Western)',
  },
  {
    date: 'January 18, 2025',
    time: '7:00 PM',
    title: 'Reception',
    description: 'Welcome the newlywed couple and celebrate the start of their journey together with an evening of delicious regional Indian dishes, dancing, and merriment.',
    dressCode: 'Black tie optional (Indian or Western)',
  },
  {
    date: 'January 19, 2025',
    time: '7:00 AM',
    title: 'Farewell Breakfast',
    description: 'Enjoy a final meal with family and friends.',
  },
];

const groupEventsByDate = (events: Event[]) => {
  return events.reduce((acc, event) => {
    if (!acc[event.date]) {
      acc[event.date] = [];
    }
    acc[event.date].push(event);
    return acc;
  }, {} as Record<string, Event[]>);
};

export const EventsSection: React.FC = () => {
  const groupedEvents = groupEventsByDate(events);
  return (
    <div className="section-section">
      <div className="section-container transparent-container">
        {Object.entries(groupedEvents).map(([date, dateEvents], dateIndex) => (
          <React.Fragment key={date}>
            <div className="date-separator">
              <span>{date}</span>
            </div>
            {dateEvents.map((event, eventIndex) => (
              <div key={`${date}-${eventIndex}`} className="event-card">
                <h4>{event.title}</h4>
                <p className="event-date-time">
                  {event.time}
                  {event.dressCode && ` • ${event.dressCode}`}
                </p>
                {/*<p>{event.description}</p>*/}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};