import React from 'react';

export const FAQSection: React.FC = () => (
  <div className="section-section">
    <div className="section-container">
      <h2>Questions & Answers</h2>
      
        <h3>When is the RSVP deadline?</h3>
        <p>Kindly respond by November 16, 2024 through the <a href="/rsvp">RSVP section on our website</a>. Please contact us at <a href="mailto:nisha.and.tarak@gmail.com">nisha.and.tarak@gmail.com</a> if there are any issues.</p>

        <h3>Can I bring a plus one?</h3>
        <p>Once you look up your invitation, you will see the guests that have been included in your party. If you need to make any updates, please contact us at <a href="mailto:nisha.and.tarak@gmail.com">nisha.and.tarak@gmail.com</a> to see if we can accommodate any changes.</p> 
      
        <h3>Can I bring my children?</h3>
        <p>We want our wedding to be a joyful event for everyone and plan to celebrate into the night! While children are welcome, our reception will be a nighttime event geared towards adults.</p>
        <p>If you would prefer a night off without the kids, limited childcare will be provided on site for small children.  However, these services may not accommodate your and your children's needs, and you may prefer to arrange alternative childcare.</p>
        <p> We will leave it up to your discretion! Just be sure to include them in your RSVP for the listed events if they will be attending!</p>
      
        <h3>Is there local transportation available?</h3>
        <p>There will be a shuttle providing service from the Courtyard & Residence Inn to the main venue location at the Richmond Marriott.</p>
    </div>
  </div>
);