import React, { useState, useEffect } from 'react';
import '../../css/sections/GallerySection.css';

export const GallerySection: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    const importAll = (r: { keys: () => string[]; (key: string): string }) => {
      return r.keys().map(r);
    };

    const imageContext = (require as any).context('../../images/gallery', false, /\.(png|jpe?g|svg)$/);
    const loadedImages = importAll(imageContext);
    setImages(loadedImages);
  }, []);

  const openLightbox = (imageSrc: string) => {
    const index = images.findIndex(img => img === imageSrc);
    setSelectedIndex(index);
    setSelectedImage(imageSrc);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
    setSelectedIndex(null);
  };

  const navigateImage = (direction: 'prev' | 'next') => {
    if (selectedIndex === null) return;
    const newIndex = direction === 'prev' 
      ? (selectedIndex - 1 + images.length) % images.length 
      : (selectedIndex + 1) % images.length;
    setSelectedIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  return (
    <div className="section-section">
      <div className="section-container">
        <div className="gallery-grid">
          {images.map((src, index) => (
            <div key={index} className={`gallery-item item-${index + 1}`} onClick={() => openLightbox(src)}>
              <img src={src} alt={`Gallery image ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
      {selectedImage && (
        <div className="lightbox" onClick={closeLightbox}>
          <img src={selectedImage} alt="Full size" onClick={(e) => e.stopPropagation()} />
          <button className="lightbox-nav prev" onClick={(e) => { e.stopPropagation(); navigateImage('prev'); }}>
            &#10094;
          </button>
          <button className="lightbox-nav next" onClick={(e) => { e.stopPropagation(); navigateImage('next'); }}>
            &#10095;
          </button>
        </div>
      )}
    </div>
  );
};