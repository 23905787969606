import React from 'react';

export const RegistrySection: React.FC = () => (
  <div className="section-section">
    <div className="section-container">
      <h2>Registry</h2>
        <p>We are excited to celebrate with you on our wedding day, and your presence is the greatest gift of all! However, should you choose to honor us with a gift, we kindly request a <a target="_blank" rel="noreferrer" href="https://www.honeyfund.com/site/alle-upadhyaya-01-18-2025">contribution to our newlywed fund</a> in lieu of traditional wedding gifts. 
        <br/>
        <br/>
        While we plan to have a card box at the reception, we kindly ask that you avoid bringing physical gifts to the venue. 
        </p> 
    </div>
  </div>
);